<template>
  <div class="card card-custom card-stretch gutter-b" v-loading="loading">
    <div class="card-header">
      <div class="card-title">
        <ul class="custom-header-ul">
          <li><router-link :to="{ name: 'canlendar'}">カレンダー</router-link></li>
          <li class="custom-li"> > </li>
          <li><a href="javascript:void(0)">予約の詳細</a></li>
        </ul>
      </div>
      <div class="card-toolbar"></div>
    </div>
    <div class="card-body">
      <div v-if="loading == false">
        <div class="tg_new_booking_nsx tg_my_booking_nsx">
          <div class="text_my_booking_hd">
            <div class="text_my_booking_ft">
              <div class="head_tag">
                <h3>予約番号：{{data.code}}</h3>
                <el-tag class="status_tag" effect="dark" type="warning">
                  <span v-if="data.status == 1">確認済</span>
                  <span v-else-if="data.status == 2">キャンセル</span>
                  <span v-else-if="data.status == 3">完了</span>
                  <span v-else-if="data.status == 4">キャンセル完了</span>
                  <span v-else>決済待ち</span>
                </el-tag>
              </div>

              <p>お客様情報</p>
              <ul>
                <li>
                  <label>
                    お名前
                  </label>
                  <strong>{{customer.name}}</strong>
                </li>
                <li>
                  <label>
                    電話番号
                  </label>
                  <strong>{{customer.phone}}</strong>
                </li>
                <li>
                  <label>
                    メールアドレス
                  </label>
                  <strong>{{customer.email}}</strong>
                </li>
              </ul>
            </div>
            <div class="text_my_booking_ft">
              <p>予約情報</p>
              <ul>
                <li>
                  <label>
                    予約情報
                  </label>
                  <strong>{{data.description}}</strong>
                </li>
                <li>
                  <label>
                    利用予定人員
                  </label>
                  <strong>{{data.people_number}}人</strong>
                </li>
                <li>
                  <label>
                    利用日時
                  </label>
                  <strong>{{strDateBooking(data.date)}}</strong>
                </li>
              </ul>
              <div class="tg_price_booking_nsx">
                <p>
                  <span class="custom-chu-in-dam">利用料金</span>
                  <strong class="custom-chu-in-dam">{{formatNumber(data.price_room)}}円</strong>
                </p>
              </div>
            </div>
          </div>
          <div v-if="this.data.order_staff.length" class="staff">
            <div class="tg_my_price_hd">
              <h3>スタッフ/Staff</h3>
              <h5 v-for="item in this.data.order_staff" :key="item.id">
                <p>{{item.name}}</p>
                <span>
                  <i>数量：{{item.quantity}}</i>
                  <u>{{formatNumber(item.price)}}円</u>
                </span>
              </h5>
            </div>

            <div class="tg_price_booking_nsx">
              <p>
                <span class="custom-chu-in-dam">スタッフ料金</span>
                <strong class="custom-chu-in-dam">{{this.sumPriceStaff()}}円</strong>
              </p>
            </div>
          </div>
          <div v-if="this.data.order_equipment.length" class="equipment">
            <div class="tg_my_price_hd">
              <h3>機材レンタル</h3>
              <div v-for="item in this.data.order_equipment" :key="item.id">
                <h5>
                  <p>{{item.name}}</p>
                  <span>
                    <i>数量：{{item.quantity}}</i>
                    <u>{{formatNumber(item.price)}}円</u>
                  </span>
                </h5>
                <ul v-html="renderEquiqment(item.data)"></ul>
              </div>
            </div>
            <div class="tg_price_booking_nsx">
              <p>
                <span class="custom-chu-in-dam">機材レンタル費</span>
                <strong class="custom-chu-in-dam">{{sumPriceEquipment()}}円</strong>
              </p>
            </div>
          </div>
          <div class="tg_total_price_nsx">
            <div class="text_total_price_ft">
              <p>
                <span class="custom-chu-in-dam">合計</span>
                <strong class="custom-chu-in-dam">{{formatNumber(data.amount)}}円</strong>
              </p>
            </div>
            <div class="bt_price_total_nsx">
              <a v-if="data.status != 5" href="javascript:void(0)" @click="dialogCancel = true" :disabled="checkStatus" v-bind:class="{'button disabled':checkStatus}">予約のキャンセル</a>
              <a v-if="data.status != 5" href="javascript:void(0)" @click="dialogComplete = true" :disabled="checkStatusButtonComplete" v-bind:class="{'button disabled':checkStatusButtonComplete}">完了</a>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
          title="予約をキャンセルしますか？"
          :visible.sync="dialogCancel"
          width="30%"
          center
      >
        <span slot="footer" >
          <div class="row">
            <div class="col-md-6 text-right">
                   <el-button style="width: 120px;" class="btn-cancel-order" @click="dialogCancel = false">いいえ</el-button>
            </div>
            <div class="col-md-6 text-left">
                 <el-button style="width: 120px;" type="primary" @click="comfirmCancelBooking()">はい</el-button>
            </div>
          </div>
        </span>
      </el-dialog>

      <el-dialog
          title="キャンセル料が発生するかもしれませんが、予約をキャンセルしますか？"
          :visible.sync="dialogComplete"
          width="30%"
          center
      >
        <span slot="footer">
          <div class="row">
            <div class="col-md-6 text-right">
                  <el-button style="width: 120px;" class="btn-cancel-order" @click="dialogComplete = false" v-bind:class="{'button disabled':checkStatus}">いいえ</el-button>
            </div>
            <div class="col-md-6 text-left">
                  <el-button style="width: 120px;" type="primary" @click="comfirmCompleteBooking()">はい</el-button>
            </div>
          </div>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

import { DETAIL_ORDER, CHANGE_STATUS, CHANGE_STATUS_COMPLETE } from "@/core/services/store/order.module";
import { STATUS_CODE } from "@/core/config/constant";

/* Status code */
const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE;

export default {
  name: "DetailOrder",
  data() {
    return {
      data: {},
      customer: {},
      loading: false,
      dialogCancel: false,
      dialogComplete:false,
      formChangeStatus: {},
      checkStatus: false,
      checkStatusButtonComplete: false,
    };
  },

  async created() {
    await this.getDetail();
    await this.handleCheckStatus();
  },

  methods: {
    async getDetail() {
      this.loading = true;
      let id = this.$route.params.id;
      let data = await this.$store.dispatch(DETAIL_ORDER, id);
      if (data.code == STATUS_CODE.SUCCESS) {
        this.data = data.data;
        this.customer = data.data.customer
      }
      this.loading = false;
    },

    strDateBooking(day) {
      let arr = day.split("-");

      let date =
          arr[0] +
          "年" +
          arr[1] +
          "月" +
          arr[2] +
          "日 " +
          this.data.start_time +
          "~" +
          this.data.end_time;

      return date;
    },

    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    sumPriceStaff() {
      let amount = 0;
      this.data.order_staff.map(x => (amount += x.price));
      return this.formatNumber(amount);
    },

    sumPriceEquipment() {
      let amount = 0;
      this.data.order_equipment.map(x => (amount += x.price));
      return this.formatNumber(amount);
    },

    renderEquiqment(data) {
      let arr = JSON.parse(data);
      let html = "";
      for (let i = 0; i < arr.length; i++) {
        html += `<li><span>${arr[i].equipment_name}</span><i>数量：${arr[i].equipment_quantity}</i></li>`;
      }
      return html;
    },

    async comfirmCancelBooking(){
      this.loading = true;
      this.dialogCancel = false;
      this.formChangeStatus.id = this.data.id;
      this.formChangeStatus.status = this.data.status;
      this.formChangeStatus.email = this.data.customer.email;
      this.formChangeStatus.name = this.data.customer.name;
      this.formChangeStatus.phone = this.data.customer.phone;
      this.formChangeStatus.code = this.data.code;
      this.formChangeStatus.date = this.data.date;
      this.formChangeStatus.time = this.data.start_time + '~' + this.data.end_time;
      this.formChangeStatus.amount = this.data.amount;
      const data = await this.$store.dispatch(CHANGE_STATUS, this.formChangeStatus );
      if (data.code === STATUS_CODE.SUCCESS) {
        this.notifySuccess(this.$t("success"));
        this.$router.push({name: 'order'})
      }
      else {
        this.notifyError(this.$t("ER_19"));
      }
      this.loading = false;
    },

    async comfirmCompleteBooking(){
      this.loading = true;
      this.formChangeStatus.id = this.data.id;
      this.formChangeStatus.status = this.data.status;
      const data = await this.$store.dispatch(CHANGE_STATUS_COMPLETE, this.formChangeStatus );
      if (data.code == STATUS_CODE.SUCCESS) {
        this.notifySuccess(this.$t("success"));
        this.dialogComplete = false;
        this.$router.push({name: 'order'})
      }
      this.loading = false;
    },
    handleCheckStatus() {
      if(this.data.status == 3 || this.data.status == 4){
        this.checkStatus = true;
        this.checkStatusButtonComplete = true
      } else if(this.data.status == 2) {
        this.checkStatus = true;
        this.checkStatusButtonComplete = false;
      }
    },
  }
};
</script>
<style scoped lang="scss">
.tg_new_booking_nsx {
  box-shadow: none !important;
  padding: 0 !important;
}

.tg_price_booking_nsx > p {
  background: #c1e4f4 !important;
}
.text_total_price_ft > p {
  background: #93d1ec !important;
}
.bt_price_total_nsx > a {
  background: grey !important;
}

.bt_price_total_nsx > a:last-child {
  background: #027db4 !important;
}
.head_tag {
  position: relative;
}

.status_tag {
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
ul {
  list-style: none;
}
ul li {
  float: left;
  margin-left: 10px;
}
.custom-li {
  color: #AAAAAA;
}
p {
  margin-bottom: 0rem;
}
.tg_new_booking_nsx.tg_my_booking_nsx .tg_total_price_nsx .bt_price_total_nsx a:last-child::before {
  background: none;
  text-align: center;
}
.tg_new_booking_nsx.tg_my_booking_nsx .tg_total_price_nsx .bt_price_total_nsx a {
  padding: 15px 30px 15px 30px;
}
.tg_new_booking_nsx.tg_my_booking_nsx .tg_total_price_nsx .bt_price_total_nsx a::before {
  background: none;
  align: center;
}
.btn-cancel {
  background-color: #AAAAAA;
  color: white;
  border-color: #AAAAAA;
}
.btn-cancel-order, .btn-cancel-order:hover {
  color: #fff;
  border-color: #AAAAAA;
  background-color: #AAAAAA;
}
ul.custom-header-ul {
  margin-left: -20px;
}
a.button.disabled{
  cursor: no-drop !important;
}
a.button.disabled{
  cursor: no-drop !important;
}
.disabled:active{
  pointer-events: none;
}
::v-deep .tg_my_price_hd ul li {
  font-size: 17px;
  line-height: 25px;
  color: #292f33;
  margin: 0px 0px 10px 20px;
  display: table;
  width: 100%;
}
.tg_my_price_hd ul li {
  display: table !important;
  width: 100%;
}
.tg_my_price_hd h5 span u {
  display: table;
  width: 100%;
  max-width: 130px;
  float: right;
  text-align: right;
}
.tg_my_price_hd h5 span i {
  margin: 0px 0px 0px 0px;
}
.tg_my_price_hd h5 span {
  display: table;
  width: 100%;
  max-width: 200px;
}
i {
  color: #000;
}
.custom-chu-in-dam {
  color: #000;
}
::v-deep .el-dialog__header{
  padding-top: 50px;
}
</style>
